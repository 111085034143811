import {
  Button,
  Card,
  Checkbox,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useMemo, useState } from "react";
import show from "../assets/icons/Show.svg";
import hide from "../assets/icons/Hide.svg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpClient } from "../axios";
import { useNavigate } from "react-router";
import back from "../assets/images/back1.jpg";
import CustomRadio from "./CustomRadio";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import logo from "../assets/Logo.png";
import {
  PayPalButtons,
  PayPalScriptProvider,
  PayPalMarks,
} from "@paypal/react-paypal-js";
import { v4 as uuid } from "uuid";

const INTIALIZE = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  gender: "",
  isactive: "",
  address1: "",
  address2: "",
  address3: "",
  zipcode: "",
  note: "",
  points: 0,
  mobileno: "",
  city: "",
  substatus: "",
  signupcode: "",
};
const validation = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string()
    .email()
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    .required(),
  password: Yup.string().min(5).required(),
  mobileno: Yup.string().required(),
  address1: Yup.string().required(),
  note: Yup.string().required(),
  zipcode: Yup.string()
    .matches(/^[0-9]{0,6}$/)
    .required(),
});

const plansList = [
  {
    id: 1,
    name: "Annual",
    value: 250,
  },
  {
    id: 2,
    name: "Lifetime",
    value: 2500,
  },
];

const Register = () => {
  const [genders, setGenders] = useState([
    {
      label: "Male",
    },
    { label: "Female" },
    { label: "Others" },
  ]);

  const [cities, setCities] = useState([]);

  const [city, setCity] = useState("");

  const [gender, setGender] = useState(null);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedAge, setIsSelectedAge] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();

  const [error, setError] = useState(null);
  useEffect(() => {
    getAllCities();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: INTIALIZE,
    validationSchema: validation,
    onSubmit: () => {
      saveUser();
    },
  });

  const [planSelect, setPlanSelect] = useState(plansList[0]);
  const [paymentMethod, setPaymentMethod] = useState("full");
  const [partailPayment, setPartialPayment] = useState(0);

  useEffect(() => {
    localStorage.setItem("pay", "full");
    localStorage.setItem("plan", JSON.stringify(plansList[0]));
  }, []);

  const saveUser = async (event) => {
    const test = {
      id: values.id,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      gender: gender,
      isactive: "Y",
      // address: values.address,
      note: values.note,
      points: values.points,
      mobileno: values.mobileno,
      city: city,
      substatus: "Yes",
      add1: values.address1,
      add2: values.address2,
      add3: values.address3,
      zipcode: values.zipcode,

      signupcode: values.signupcode,
    };

    console.log(test);

    httpClient.post("/usersignUp", test).then((res) => {
      try {
        // console.log(res);
        if (res.data.statusCode === "202") {
          // navigate("/login");
          localStorage.setItem("emailID", res.data.result?.email);
          setShowPaymentForm(true);
        }
        toast.success(res.data.message);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const getAllCities = () => {
    httpClient.get("getAllCity").then((res) => {
      // console.log(res);
      if (res.data.statusCode === "200") {
        setCities(res.data.result);
        let cityf = res.data.result.find((a) => a.cityname == "Texas");
        // console.log(cityf);
        setCity(cityf.cityname);
      }
    });
  };

  const getRegDisable = () => {
    return !isSelected || !isSelectedAge;
  };

  const [formSubmit, setFoemSubmit] = useState(false);

  const checkFormValidation = (e) => {
    e.preventDefault();
    setFoemSubmit(true);

    handleSubmit();
  };

  const initialOptions = {
    "client-id":
      "AfoLh6XRzuyrLdoJxORIiDe4jIiHRuZDyNJK5WwcYPhyHnpkdZlZ9XcDiX7CK6PjYEGC5jZUDt9yub1L",
    currency: "USD",
    intent: "capture",
    components: "marks,buttons",
  };

  const fundingSources = ["paypal", "card"];

  const [isCheck, setIsCheck] = useState(false);
  const [isChequeNo, setIsChequeNo] = useState(null);
  const [isChequeValid, setIsChequeValid] = useState(false);
  // Remember the amount props is received from the control panel
  const [selectedFundingSource, setSelectedFundingSource] = useState(
    fundingSources[0]
  );

  function onChange(event, type = "paypal") {
    // console.log(event.target.value);
    if (type == "paypal") {
      setSelectedFundingSource(event.target.value);
      setIsCheck(false);
    } else {
      setIsCheck(true);
    }
  }

  async function createOrder() {
    try {
      const response = await fetch(
        "https://determined-boa-pea-coat.cyclic.app/api/orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // use the "body" param to optionally pass additional order information
          // like product ids and quantities
          body: JSON.stringify({
            cart: [
              {
                name: JSON.parse(localStorage.getItem("plan")).name,
                value:
                  localStorage.getItem("pay") == "partial"
                    ? JSON.parse(localStorage.getItem("plan")).value / 2
                    : JSON.parse(localStorage.getItem("plan")).value,
              },
            ],
          }),
        }
      );

      const orderData = await response.json();

      console.log(orderData);

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      console.log(`Could not initiate PayPal Checkout...<br><br>${error}`);
    }
  }

  async function onApproveFunc(data, actions) {
    console.log(data);
    try {
      const response = await fetch(
        `https://determined-boa-pea-coat.cyclic.app/api/orders/${data.orderID}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const orderData = await response.json();
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        return actions.restart();
      } else {
        const transaction =
          orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
          orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

        console.log(transaction);
        savePayment(transaction, orderData?.payer);

        // transaction.status === "COMPLETED"
        //   ? toast.success("Transaction Completed", transaction.status)
        //   : toast.success("Transaction Failed", transaction.status);
      }
    } catch (error) {
      console.error(error);
      console.log(
        `Sorry, your transaction could not be processed...<br><br>${error}`
      );
    }
  }

  const changePaymentMethod = (e) => {
    console.log(e);
    localStorage.setItem("pay", e);
    setPaymentMethod(e);
    if (e === "partial") {
      setPartialPayment(planSelect.value / 2);
    } else {
      setPartialPayment(0);
    }
  };

  const onPlanChange = (e) => {
    localStorage.setItem("plan", JSON.stringify(e));
    setPlanSelect(e);
    setPartialPayment(0);
    setPaymentMethod("full");
  };

  const savePayment = async (pay, user) => {
    console.log(isCheck);
    if (isCheck) {
      if (isChequeNo?.length > 0 || isChequeNo !== null) {
        const payment = {
          emailid: localStorage.getItem("emailID"),
          trandate: pay?.create_time,
          transactionid: isCheck ? uuid() : pay?.id,
          planname: planSelect.name,
          planvalue: isCheck
            ? localStorage.getItem("pay") == "partial"
              ? planSelect.value / 2
              : planSelect.value
            : pay?.amount?.value,
          status: isCheck ? "COMPLETED" : pay?.status,
          paymenttype: paymentMethod,
          mode: isCheck ? "cheque" : "online",
          chequeno: isChequeNo,
        };

        // console.log(payment);

        httpClient.post("/transaction", payment).then((res) => {
          try {
            // console.log(res);
            navigate("/success");
            localStorage.setItem(
              "transaction",
              JSON.stringify(res.data.result)
            );
            toast.success(res.data.message);
          } catch (err) {
            toast.error("Something went wrong");
          }
        });
      } else {
        setIsChequeValid(true);
      }
    } else {
      const payment = {
        emailid: localStorage.getItem("emailID"),
        trandate: pay?.create_time,
        transactionid: isCheck ? uuid() : pay?.id,
        planname: planSelect.name,
        planvalue: isCheck
          ? localStorage.getItem("pay") == "partial"
            ? planSelect.value / 2
            : planSelect.value
          : pay?.amount?.value,
        status: isCheck ? "COMPLETED" : pay?.status,
        paymenttype: paymentMethod,
        mode: isCheck ? "cheque" : "online",
        chequeno: isChequeNo,
      };

      // console.log(payment);

      httpClient.post("/transaction", payment).then((res) => {
        try {
          // console.log(res);
          navigate("/success");
          localStorage.setItem("transaction", JSON.stringify(res.data.result));
          toast.success(res.data.message);
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const openModel = () => {
    if (!isSelected) onOpen();
  };

  // const submitCheque = () => {
  //   console.log(isChequeNo);
  // }

  return (
    <div
      className={`flex items-center px-4 md:px-0 justify-center py-20 ${
        showPaymentForm ? "h-auto min-h-dvh max-h-full" : "h-full"
      } `}
      style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}
    >
      <Card className="p-8 w-full md:w-[55vw] overflow-y-scroll h-[90vh] md:h-auto md:overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-bold text-4xl ">Sign Up</h1>
          <img src={logo} alt="Logo" className="w-40" />
        </div>
        <div className="">
          {showPaymentForm ? (
            <div>
              <PayPalScriptProvider options={initialOptions}>
                <RadioGroup
                  value={planSelect}
                  onValueChange={onPlanChange}
                  className="w-full "
                  label="Plans"
                >
                  {plansList?.map((item) => {
                    return (
                      <CustomRadio
                        key={item.id}
                        description={`$${item.value}`}
                        value={item}
                      >
                        {item.name}
                      </CustomRadio>
                    );
                  })}
                </RadioGroup>
                <RadioGroup
                  value={paymentMethod}
                  onValueChange={changePaymentMethod}
                  label="Select Payment Method"
                  color="primary"
                  className="my-4"
                >
                  <Radio value="full">Full Payment</Radio>
                  <Radio
                    value="partial"
                    description="Rest Amount will be paid after six months"
                  >
                    <div className="relative">
                      <span>Partial Payment </span>
                      {partailPayment != 0 && (
                        <span className="absolute -right-40 font-medium">
                          ${partailPayment} to be paid after 6 months
                        </span>
                      )}
                    </div>
                  </Radio>
                </RadioGroup>
                <div className="flex gap-8 items-center my-4">
                  {fundingSources.map((fundingSource) => (
                    <label
                      className="mark flex  items-center  gap-2"
                      key={fundingSource}
                    >
                      <input
                        defaultChecked={fundingSource === selectedFundingSource}
                        onChange={(e) => onChange(e)}
                        type="radio"
                        name="fundingSource"
                        value={fundingSource}
                      />
                      <PayPalMarks fundingSource={fundingSource} />
                    </label>
                  ))}
                  <div className="flex items-center gap-2">
                    <input
                      value={isCheck}
                      checked={isCheck}
                      onChange={(e) => onChange(e, "check")}
                      type="radio"
                    />
                    <span className="border p-1 cursor-default">Cheque</span>
                  </div>
                </div>
                <PayPalButtons
                  className={`${isCheck ? "hidden" : "flex"}`}
                  fundingSource={selectedFundingSource}
                  createOrder={createOrder}
                  onApprove={onApproveFunc}
                  style={{ layout: "vertical", tagline: false }}
                />
              </PayPalScriptProvider>
              <div
                className={`${!isCheck ? "hidden" : "flex"} items-center gap-4`}
              >
                <Input
                  value={isChequeNo}
                  onChange={(e) => setIsChequeNo(e.target.value)}
                  type="text"
                  isRequired
                  isInvalid={Boolean(isChequeValid && isChequeNo == null)}
                  label="Cheque Number"
                />
                <Button onClick={savePayment} color="primary">
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <form
              id="reg"
              className="flex flex-col gap-6 w-full "
              noValidate
              onSubmit={checkFormValidation}
            >
              <div className="flex flex-col gap-4 md:flex-row items-start">
                <Input
                  isRequired
                  label="First Name"
                  type="text"
                  labelPlacement="outside"
                  value={values.firstname}
                  name="firstname"
                  isInvalid={Boolean(touched.firstname && errors.firstname)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  isRequired
                  label="Last Name"
                  type="text"
                  labelPlacement="outside"
                  value={values.lastname}
                  name="lastname"
                  isInvalid={Boolean(touched.lastname && errors.lastname)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="flex flex-col gap-4 md:flex-row items-start">
                <Input
                  isRequired
                  label="Email"
                  type="email"
                  labelPlacement="outside"
                  value={values.email}
                  name="email"
                  isInvalid={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  isRequired
                  label="Mobile Number"
                  labelPlacement="outside"
                  value={values.mobileno}
                  name="mobileno"
                  isInvalid={Boolean(touched.mobileno && errors.mobileno)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="flex gap-4 items-start">
                <Input
                  isRequired
                  label="Create New Password"
                  labelPlacement="outside"
                  type={isVisible ? "text" : "password"}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <Image alt="Hide" src={hide} />
                      ) : (
                        <Image alt="Show" src={show} />
                      )}
                    </button>
                  }
                  value={values.password}
                  name="password"
                  isInvalid={Boolean(touched.password && errors.password)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    Boolean(touched.password && errors.password) &&
                    "Please enter minimum 5 characters"
                  }
                />
                <Select
                  label="Gender"
                  name="gender"
                  labelPlacement="outside"
                  isRequired
                  isInvalid={formSubmit && Boolean(gender == null)}
                  onChange={handleGenderChange}
                >
                  {genders.map((gender) => (
                    <SelectItem key={gender.label} value={gender.label}>
                      {gender.label}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="flex gap-4 items-start">
                <Input
                  label="Referral Code"
                  labelPlacement="outside"
                  value={values.signupcode}
                  name="signupcode"
                  isInvalid={Boolean(touched.signupcode && errors.signupcode)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    Boolean(touched.signupcode && errors.signupcode) &&
                    "Please enter a valid Referral Code"
                  }
                />
                <Input
                  label="Address 1"
                  labelPlacement="outside"
                  value={values.address1}
                  name="address1"
                  isInvalid={Boolean(touched.address1 && errors.address1)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired
                />
              </div>

              <div className="flex gap-4 items-start">
                <Input
                  label="Address 2"
                  labelPlacement="outside"
                  value={values.address2}
                  name="address2"
                  isInvalid={Boolean(touched.address2 && errors.address2)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    Boolean(touched.address2 && errors.address2) &&
                    "Please enter a valid Address 2"
                  }
                />
                <Input
                  label="Address 3"
                  labelPlacement="outside"
                  value={values.address3}
                  name="address3"
                  isInvalid={Boolean(touched.address3 && errors.address3)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    Boolean(touched.address3 && errors.address3) &&
                    "Please enter a valid Address 3"
                  }
                />
              </div>
              <div className="flex flex-col gap-4 md:flex-row items-start">
                <Select
                  label="State"
                  labelPlacement="outside"
                  defaultSelectedKeys={["Texas"]}
                  onChange={handleCityChange}
                >
                  {cities.map((c) => (
                    <SelectItem key={c.cityname} value={c.cityname}>
                      {c.cityname}
                    </SelectItem>
                  ))}
                </Select>
                <Select
                  label="Country"
                  labelPlacement="outside"
                  isDisabled={true}
                  defaultSelectedKeys={["USA"]}
                >
                  <SelectItem key="USA" value="USA">
                    USA
                  </SelectItem>
                </Select>
              </div>

              <div className="flex gap-4 items-start">
                <Input
                  label="Zip Code"
                  labelPlacement="outside"
                  value={values.zipcode}
                  name="zipcode"
                  isInvalid={Boolean(touched.zipcode && errors.zipcode)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired
                />
                <Textarea
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(touched.note && errors.note)}
                  label=""
                  placeholder="Why are you become a member?"
                  labelPlacement="outside"
                  isRequired
                />
              </div>
              <div className="mt-7 flex flex-col ">
                <div className="mb-2">
                  <div>
                    <Checkbox
                      isSelected={isSelected}
                      onValueChange={setIsSelected}
                      isRequired
                      isInvalid={!isSelected}
                      onClick={openModel}
                    ></Checkbox>{" "}
                    <span
                      className={`cursor-pointer text-blue-600  
                     underline`}
                      onClick={onOpen}
                    >
                      Disclosure & Waiver{" "}
                      <span className="ml-2 text-danger underline">
                        {!isSelected &&
                          formSubmit &&
                          "Please Read Disclosure, Waiver and Accept"}
                      </span>
                    </span>
                  </div>

                  <Checkbox
                    isSelected={isSelectedAge}
                    onValueChange={setIsSelectedAge}
                    isRequired
                    isInvalid={!isSelectedAge}
                  >
                    Are you 18 or older?
                  </Checkbox>
                  <span className="ml-2 text-danger underline">
                    {!isSelectedAge && formSubmit && "Please Accept The Age"}
                  </span>
                  <Modal
                    size="xl"
                    scrollBehavior="inside"
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                  >
                    <ModalContent>
                      {(onClose) => (
                        <>
                          <ModalHeader className="flex flex-col gap-1">
                            Disclosure & Waiver
                          </ModalHeader>
                          <ModalBody>
                            <p>
                              By signing for Membership of Indo American Charity
                              Foundation (herein “IACF”), the Member(s) agree to
                              abide by IACF Constitution Articles, Bylaws, Rules
                              & Regulations and Board Resolutions, whether
                              currently in force or subsequently adopted.
                              Further, the Member(s) hereby voluntarily
                              relinquishes, revokes, his/her right to hold IACF
                              and/ or its facility owned or rented, and/ or its
                              officers, and/ or its board members, and/ or its
                              volunteers, and/ or its collaborators, and/ or its
                              partners, and/ or its members liable for any act
                              of omission that may result in any either legal or
                              moral accountability, whether or not financial in
                              nature, thereby waiving the right to any legal
                              remedy that may otherwise be available at law or
                              in equity. Additionally, with regards to and all
                              programs sponsored by IACF and/ or its
                              collaborators, and/ or its partners, the member(s)
                              agrees to, without dispute, not only honor,
                              respect, dignify the scheduled event, but also to
                              heed and comply with any ritual (including
                              religious observance) as directed by IACF
                              officer(s) and/ or person(s) to whom such
                              authority has been delegated by some or all of
                              IACF. Member(s) fully understands and acknowledges
                              that any violation of the terms contained herein
                              will be regarded as good cause for removal from
                              the particular event, and may also result in
                              suspension, temporary or permanent loss of IACF
                              Membership.
                            </p>
                            <ul className="list-disc ml-4 text-gray-800">
                              <li>IACF Membership is non-transferrable.</li>
                              <li>Members do not have right to vote.</li>
                              <li>
                                Members may be eligible to join the IACF Board
                                of Directors, based on their performance,
                                contribution and referral from an existing Board
                                Member, per process outlined in the Bylaws.
                              </li>
                            </ul>

                            <p>
                              Completely filled application required, incomplete
                              application will not be considered. All IACF
                              Membership application is subject to review and
                              approval by IACF.
                            </p>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="danger"
                              variant="light"
                              onPress={onClose}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </>
                      )}
                    </ModalContent>
                  </Modal>
                </div>
                <Button
                  className="flex-1 py-2 text-sm mb-1"
                  type="submit"
                  color="primary"
                >
                  Sign Up
                </Button>

                <p>
                  Already has account ? <Link href="/#/login">Login</Link>
                </p>
              </div>
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Register;
